import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import Menu from '../menus/menu';

/**
 * @description This components renders footer navigation
 */
export default class FooterMenu extends Menu {

    /**
     * @description Render single menu item
     * @param node
     * @returns {*}
     */
    renderFooterLink(node) {
        // Is link external or internal? If it starts with slash ( / ), its internal
        const internal = /^\/(?!\/)/.test(node.alias);
        if (internal) {
            return (
                <Link className="text-white" key={node.id} to={node.alias}>{node.title}</Link>
            );
        }

        return (
            <a className="pr-2 text-white" key={node.id} target="_blank" rel="noopener noreferrer" href={node.alias}>{node.title}</a>
        );
    }

    /**
     * @description Recursive renderer of menu item
     * @param node
     */
    renderMenuObject(node) {
        return (
            <li key={node.id}>
                <div>{this.renderFooterLink(node)}</div>
                {(node.children && node.children.length > 0) ? (
                    <ul>
                        {node.children && node.children.map(node => this.renderMenuObject(node))}
                    </ul>
                ) : (
                    <></>
                )}
            </li>
        );
    }

    /**
     * @description Render menu
     * @param edges
     * @returns {*}
     */
    renderMenu(edges) {
        const tree = this.convertToTree(edges).sort((a, b) => a.weight - b.weight);

        return (
            <ul>
                {tree.map(node => this.renderMenuObject(node))}
            </ul>
        );
    }

    /**
     * @description Render menu
     * @returns {*}
     */
    render() {
        return (
            <StaticQuery
                query={graphql`
                        query FooterNavQuery {
                          allMenuLinkContentMenuLinkContent(sort: {order: ASC, fields: weight}, filter: { menu_name: {eq: "footer"}}) {
                            edges {
                              node {
                                id
                                title
                                alias
                                weight
                                
                                drupal_internal__id
                                parent_drupal_internal__id
                                menu_name
                              }
                            }
                          }
                        }
                        `}
                render={data => (
                    <>
                        <div className="col-sm-6 col-md-7 col-lg-8 offset-xl-1">
                            <nav className="nav d-flex">
                                {this.renderMenu(data.allMenuLinkContentMenuLinkContent.edges)}
                            </nav>
                        </div>
                    </>
                )}
            />
        );
    }
}
