import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
    const { pathname } = useLocation()
    const { site, logo } = useStaticQuery(query)
    const {
        defaultTitle,
        siteUrl,
    } = site.siteMetadata
    const seo = {
        title: title || defaultTitle,
        keywords: title || 'college application, transfer application, transfer admissions, college admissions, common app, common application, university application, university admissions',
        description: description || 'Apply to college for the first time or transfer to complete your degree with Common App. Join more than 1 million students applying to college with Common App.',
        image: `${siteUrl}${image || logo.path}`,
        url: `${siteUrl}${pathname}`,
    }
    return (
        <Helmet title={seo.title}>
            <html lang="en" />
            <meta name="keywords" content={seo.keywords} />
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
                <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={"CommonApp"} />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
                <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
        </Helmet>
    )
}
export default SEO
SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    article: PropTypes.bool,
}
SEO.defaultProps = {
    title: null,
    description: null,
    image: null,
    article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        siteUrl: siteUrl
      }
    }
      logo: file(base:{eq:"ca-icon-blue.png"})
      {
        path: publicURL
      }
  }`
