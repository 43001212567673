import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Menu from '../menus/menu';

/**
 * @description Renders side navigation
 */
export default class LegalMenu extends Menu {

    /**
     * @description Render menu
     * @param edges
     * @returns {*}
     */
    renderMenu(edges) {
        return (
            <ul>
                {edges.map((edge, key) => <li key={key}>{this.renderLink(edge.node)}</li>)}
            </ul>
        );
    }

    /**
     * @description Render menu
     * @returns {*}
     */
    render() {
        return (
            <StaticQuery
                query={graphql`
                        query LegalNavQuery {
                          allMenuLinkContentMenuLinkContent(sort: {order: ASC, fields: weight}, filter: { menu_name: {eq: "legal-navigation"}}) {
                            edges {
                              node {
                                id
                                title
                                alias
                                weight
                                
                                drupal_internal__id
                                parent_drupal_internal__id
                                menu_name
                              }
                            }
                          }
                        }
                        `}
                render={data => (
                    <>
                        {this.renderMenu(data.allMenuLinkContentMenuLinkContent.edges)}
                    </>
                )}
            />
        );
    }
}
