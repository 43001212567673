import React, {Component} from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';
import removeTrailingSlash from '../utils/remove-trailing-slash';
import SEO from "../templates/seo";

export default class Layout extends Component {


    /**
     * Render layout
     * @returns {*}
     */
    render()
    {
        return (
            <StaticQuery
                query={graphql`
                      query SiteTitleQuery {
                        site {
                          siteMetadata {
                            title
                            drupalURL
                          }
                        }
                      }
                `}
                render={data => this.renderLayout(data)}
            />
        )
    }

    /**
     * Render layout dom + data prep
     * @param data
     * @returns {*}
     */
    renderLayout(data)
    {
        if(typeof window !== "undefined")
            window.backendUrl = removeTrailingSlash(data.site.siteMetadata.drupalURL);

        return (
            <>
                <SEO
                    title={data.site.siteMetadata.title}
                >
                </SEO>
                <div>
                    <Header inverted={this.props.invertHeader || false} />
                    {this.props.children}
                    <Footer />
                </div>
            </>
        )
    }
}
